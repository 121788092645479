import {
    Card,
    Table,
    TableContainer,
    TablePagination,
    Grid,
    Typography,
    Avatar,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Paper,
    Stack,
    useMediaQuery,
    useTheme,
    Divider,
    createTheme,
    Box,
    Tooltip,
    IconButton,
    Tab,
    Tabs,
} from '@mui/material';
import { useState } from 'react';
import HttpServices from 'src/services/httpService';
import { userModuleURL, imageModeuleUrl, postsModuleURL } from 'src/services/urlService';
import { useToaster } from 'src/utils/toaster/toasterContext';
import NoApproval from '../../../assets/app-image/no-approval-posts.svg';
import { PostDetailsHelper } from 'src/utils/postDetailsHelper';
import { CommonHelper } from 'src/utils/commonHelper';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AccountBoxSharp as AccountBoxSharpIcon } from '@mui/icons-material';
import { useRightSidebar } from 'src/layouts/right-side-bar/rightSidebarProvider';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import PublicPostPreview from '../public-post-preview.component';
import PublicPostComments from '../public-post-comment.component';
import { useParams, useNavigate } from 'react-router-dom';
import { capitalCase } from "change-case";
import ConfirmationDialog from "src/components/ConfirmationDialog";
import PendingApprovalGridActionPopup from './scheduled-post-grid-action-popup.component';

const PendingApprovalGrid = ({ scheduledPostList, handleReCallScheduledPost, publicUserName, order, orderBy }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    //Table Variables
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedPopOverPost, setPopOverPost] = useState(null);
    const [popoverElement, setPopoverElement] = useState(null);
    const [height, setHeight] = useState(window.innerHeight - 110);
    const [loading, setLoading] = useState(false);
    const [confirmationDialogue, setConfirmationDialogue] = useState(null);
    const { showToast } = useToaster();
    const respwidth = useMediaQuery(theme.breakpoints.up('xl'));
    const isMobile = useMediaQuery('(max-width:599px)');
    const { urlKey } = useParams();
    const POPUP_WIDTH = isMobile ? '300px' : '500px';

    const ProfileTime = (scheduledTime) => {
        return (
            <>
                <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ mt: 1 }}>
                    {CommonHelper.formatDateAndTime(scheduledTime)}
                </Stack>
            </>
        );
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        let orderStr = isAsc ? 'desc' : 'asc'
        handleReCallScheduledPost(property, orderStr)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleCloseMenu = () => {
        setPopOverPost(null);
        setPopoverElement(null);
    };

    const handleEdit = (imageData = []) => {
    };
    const failureCallback = (message) => {
        message && showToast(message, 'e');
        handleCloseMenu();
    };
    const handleOpenScheduledPost = (row, open = false) => {
        let data = {
            content: postView,
            data: row,
        };
        showRightSidebar(data, POPUP_WIDTH);
    };

    const postView = (row) => {
        let postDetails = {
            data: { id: row },
        };
        return <PublicDetailsPreview postDetails={postDetails} />;
    };

    const { showRightSidebar } = useRightSidebar();

    const ACCOUNT_TABS = (postDetails) => [
        {
            value: 'Preview Event',
            icon: <AccountBoxSharpIcon sx={{ width: 25, height: 25, mb: '0 !important' }} />,
            component: <PublicPostPreview postDetails={postDetails} urlKey={urlKey} />,
        },
        {
            value: 'Review Comments',
            icon: <AccountBoxSharpIcon sx={{ width: 25, height: 25, mb: '0 !important' }} />,
            component: <PublicPostComments PostDetails={postDetails} urlKey={urlKey} publicUserName={publicUserName} />,
        }
    ];

    const PublicDetailsPreview = ({ postDetails }) => {
        const tabs = ACCOUNT_TABS(postDetails);
        const [currentTab, setCurrentTab] = useState(tabs[0].value);

        const handleChange = (event, newValue) => {
            setCurrentTab(newValue);
        };

        return (
            <Box>
                <Tabs
                    value={currentTab}
                    onChange={handleChange}
                    scrollButtons="auto"
                    variant="scrollable"
                    sx={{ gap: '0.5rem', minHeight: '37px' }}
                    allowScrollButtonsMobile
                >
                    {tabs.map((tab) => (
                        <Tab
                            key={tab.value}
                            value={tab.value}
                            label={tab.value}
                            icon={tab.icon}
                            sx={{
                                flexDirection: 'row',
                                gap: '0.25rem',
                                padding: 1,
                                minHeight: '10px',
                                mr: '0.75rem',
                                ml: '0.75rem',
                                fontWeight: 600,
                            }}
                            disableRipple
                        />
                    ))}
                </Tabs>
                <Box sx={{ mb: 2 }} />
                <Box>
                    {tabs.find((tab) => tab.value === currentTab)?.component}
                </Box>
            </Box>
        );
    };

    const handleUnApprovePost = (row) => {
        handleCloseMenu()
        setConfirmationDialogue({
            "isOpen": true,
            "title": "Confirm Post Now",
            "message": "Are you sure you want to Un Approve this Schedule?",
            submitFunction: () => confirmUnApprovePost(row)
        })
    };

    const confirmUnApprovePost = (row) => {
        let props = {
            schedule_id: row.id,
            name: publicUserName,
        };
        let postProps = {
            url: userModuleURL.unApprovePostPublic + `?url_key=${urlKey}`,
            body: props,
            successCallback: approveUnApproveSuccessCallback,
            failureCallback,
        };
        HttpServices.Post(postProps);
    };

    const approveUnApproveSuccessCallback = (data, message) => {
        message && showToast(message);
        handleCloseMenu();
        closeConfirmationDialogue()
        handleReCallScheduledPost();
    };

    const postActionProps = {
        popoverElement: popoverElement,
        popoverElement,
        handleCloseMenu,
        loading,
        selectedPopOverPost: selectedPopOverPost,
    };

    const closeConfirmationDialogue = () => {
        setConfirmationDialogue(null)
    };
    return (
        <>
            {scheduledPostList && scheduledPostList.length > 0 ? (
                <>
                    <Box style={{ justifyContent: 'center', alignItems: 'center', minHeight: 'calc(100vh - 200px)', maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                        {scheduledPostList.map(row => {
                            return (
                                <Stack container justifyContent="center" alignItems="center" >
                                    <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '0.5rem', pb: 2 }}>
                                        {!isMobile && PostDetailsHelper.getProfile(row.created_by.name, row.created_by.email, row.created_by.profile_pic)}
                                        <Stack
                                            onClick={() => {
                                                handleOpenScheduledPost(row.id, true);
                                            }}
                                        >
                                            <Card
                                                sx={{
                                                    maxWidth: !isMobile ? 500 : 340,
                                                    minWidth: !isMobile ? 500 : 340,
                                                    boxShadow: 'none',
                                                    border: 'solid 2px',
                                                    borderColor: theme.palette.preview.postcard.color,
                                                }}
                                            >
                                                <CardHeader
                                                    title={PostDetailsHelper.getSocialMediaProfiles(row?.connected_profile_details)}
                                                    subheader={<ProfileTime scheduledTime={row?.scheduled_time} />}
                                                    action={<IconButton
                                                        size="large"
                                                        color="inherit"
                                                        sx={{ padding: '6px', marginRight: '2px' }}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>}
                                                />
                                                <CardContent sx={{ pt: 0, pb: 0 }}>
                                                    <Typography
                                                        style={{
                                                            display: '-webkit-box',
                                                            WebkitBoxOrient: 'vertical',
                                                            WebkitLineClamp: 2,
                                                            overflow: 'hidden',
                                                            WebkitBoxDecorationBreak: 'clone',
                                                            whiteSpace: 'pre-line',
                                                            textOverflow: 'ellipsis',
                                                        }}
                                                    >
                                                        {row.description}
                                                    </Typography>
                                                </CardContent>
                                                <CardMedia sx={{ pl: 2, pr: 2 }}>{PostDetailsHelper.getPublicPostMedias(row?.media_url)}</CardMedia>
                                                <CardActions disableSpacing>
                                                    <Divider />
                                                    <Grid container spacing={2} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <Grid item xs={4}>
                                                            <Paper
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    padding: '8px',
                                                                    cursor: 'pointer',
                                                                    transition: 'background-color 0.3s',
                                                                    '&:hover': {
                                                                        backgroundColor: theme.palette.secondary.main,
                                                                    },
                                                                    color: theme.palette.error.main,
                                                                }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleUnApprovePost(row);
                                                                }}
                                                            >
                                                                <CheckCircleOutlinedIcon />
                                                                {!isMobile && <Typography sx={{ fontWeight: 700, ml: 1 }}>Un Approve</Typography>}
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Paper
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    padding: '8px',
                                                                    cursor: 'pointer',
                                                                    transition: 'background-color 0.3s',
                                                                    '&:hover': {
                                                                        backgroundColor: theme.palette.secondary.main,
                                                                    },
                                                                    color: theme.palette.comments
                                                                }}
                                                            >
                                                                <QuestionAnswerRoundedIcon />
                                                                {!isMobile && <Typography sx={{ fontWeight: 700, ml: 1 }}>Review Comments</Typography>}
                                                            </Paper>
                                                        </Grid>
                                                    </Grid>
                                                </CardActions>
                                            </Card>
                                        </Stack>
                                    </Stack>
                                    <PendingApprovalGridActionPopup {...postActionProps} approvePostDetails={row} />
                                </Stack>)
                        })}
                        {confirmationDialogue && <ConfirmationDialog
                            open={confirmationDialogue.isOpen}
                            onClose={() => closeConfirmationDialogue()}
                            onConfirm={confirmationDialogue.submitFunction}
                            title={confirmationDialogue.title}
                            message={confirmationDialogue.message}
                        />}
                    </Box>
                </>
            ) : (
                <Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)' }}>
                    <img src={NoApproval} style={{ width: '50%', height: '50%', maxWidth: '50%', maxHeight: '50%' }} alt="No Pending Approval" />
                </Card>
            )
            }
        </>
    );
};
export default PendingApprovalGrid;
