import { ErrorOutline as ErrorOutlineIcon, ScheduleSend as ScheduleSendIcon, Send as SendIcon } from '@mui/icons-material';
import { LoadingButton } from "@mui/lab";
import PostAddIcon from '@mui/icons-material/PostAdd';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ApprovalIcon from '@mui/icons-material/CheckCircle';
import { Card, FormControlLabel, IconButton, Popover, Stack, TextField, Typography, Select, ButtonGroup, Button, MenuItem, Menu, Divider } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useState } from 'react';
import { createTheme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LocalizationProvider, MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePickerToolbar } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from "dayjs";
import { SVGComponent } from "src/components/svg/svg-component";
import { CommonHelper } from "src/utils/commonHelper";
import Savedraft from '../../assets/icons/save-draft.svg';
import { NewPostStyledComponent } from "./newPostStyled.component";

const PostFooter = ({
    isSubmittingDraft,
    handleSaveDraft,
    scheduleDateTime,
    handleScheduleDate,
    whenToPost,
    PostOption,
    hasApproverPermission,
    handlePostTypeChange,
    handleFormsubmit,
    handleSelectPostTypeChange,
    isSubmitting
}) => {

    //Common Context
    const theme = useTheme();
    const customTheme = createTheme({
        breakpoints: {
            values: {
                mdCustom: 1100,
            },
        },
    });

    const isDesktop = useMediaQuery(customTheme.breakpoints.up('mdCustom'));
    const isMobile = useMediaQuery('(max-width:599px)');

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleOptionSelect = (option) => {
        handleSelectPostTypeChange(option);
        handleMenuClose();
    };

    const submitButtonView = () => {
        switch (whenToPost) {
            case PostOption.PostNow.Name:
                return <LoadingButton loading={isSubmitting} onClick={handleFormsubmit} endIcon={<SendIcon />}>
                    {PostOption.PostNow.Label}
                </LoadingButton>;
            case PostOption.SchedulePost.Name:
                return <LoadingButton loading={isSubmitting} onClick={handleFormsubmit} endIcon={<ScheduleIcon />}>
                    {PostOption.SchedulePost.Label}
                </LoadingButton>;
            case PostOption.ApprovalPost.Name:
                return <LoadingButton loading={isSubmitting} onClick={handleFormsubmit} endIcon={<ApprovalIcon />}>
                    {PostOption.ApprovalPost.Label}
                </LoadingButton>;
            default:
                return null;  // Optionally return a default icon or null
        }
    };

    const CustomDateTimePickerToolbar = (props) => {
        return (
            <DateTimePickerToolbar {...props} ampmInClock>
                <div style={{ position: 'absolute', top: 16, right: 16 }}>
                    {props.ampm && (
                        <>
                            <span style={{ fontSize: '1.5rem' }}>:</span>
                            <span style={{ fontSize: '1rem', marginLeft: '4px' }}>{props.ampm}</span>
                        </>
                    )}
                </div>
            </DateTimePickerToolbar>
        );
    };

    return (
        <>
            {!isDesktop ? <Card style={{ width: '100%', marginBottom: '7.5rem', marginLeft: '1rem', marginTop: '0.2rem', padding: '0.7rem' }} >
                <Stack display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ height: '3rem' }}>
                    <IconButton
                        onClick={handleSaveDraft}
                    >
                        <img src={Savedraft} alt="Savedraft_Icon"
                            width={25}
                            height={25} />
                    </IconButton>
                    <Stack display="flex" flexDirection="row" style={{ alignItems: 'center' }}>
                        {whenToPost !== PostOption.SchedulePost.Name && !isMobile &&
                            <Stack direction="row" alignItems="center" sx={{ mr: 2 }}>
                                <ErrorOutlineIcon sx={{ color: theme.palette.primary.main, mr: 0.5 }} />
                                <Typography variant="caption" color="black">
                                    YOUR POST SCHEDULED ON
                                    <span
                                        style={{
                                            cursor: 'pointer',
                                            textDecoration: 'underline',
                                            color: theme.palette.primary.main,
                                            marginLeft: '4px',
                                        }}
                                    >
                                        {CommonHelper.formatDateAndTime(scheduleDateTime)}
                                    </span>
                                </Typography>
                            </Stack>
                        }
                        {whenToPost !== PostOption.PostNow.Name && (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDateTimePicker
                                    placeholder="Select Time"
                                    value={dayjs(scheduleDateTime)}
                                    onChange={handleScheduleDate}
                                    disabled={whenToPost !== PostOption.SchedulePost.Name}
                                    renderInput={(props) => (
                                        <TextField
                                            {...props}
                                            size="small"
                                        />
                                    )}
                                    ToolbarComponent={CustomDateTimePickerToolbar}
                                />
                            </LocalizationProvider>
                        )}
                        <FormControlLabel
                            control={
                                <NewPostStyledComponent.SwitchStyle
                                    sx={{ mr: whenToPost !== PostOption.PostNow.Name && 1, ml: 2 }}
                                    size="small"
                                    checked={whenToPost !== PostOption.PostNow.Name}
                                    onChange={handlePostTypeChange}
                                />
                            }
                            label={whenToPost !== PostOption.PostNow.Name && <Typography variant="body1" color="black">Is Scheduled Post</Typography>}
                        />
                        <IconButton
                            onClick={handleFormsubmit}
                        >{whenToPost !== PostOption.PostNow.Name ? <ScheduleSendIcon style={{ color: theme.palette.primary.main }} /> : <SendIcon style={{ color: theme.palette.primary.main }} />}

                        </IconButton>
                    </Stack>
                </Stack>
            </Card> :
                <Card style={{ width: '100%', marginLeft: '1rem', marginTop: '0.2rem', padding: '0.7rem', marginBottom: '4rem' }}>
                    <Stack display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ height: '3.5rem' }}>
                        <LoadingButton
                            variant="contained"
                            loading={isSubmittingDraft}
                            endIcon={<SVGComponent.DraftIcon />}
                            onClick={handleSaveDraft}
                        >
                            Save As Draft
                        </LoadingButton>
                        <Stack display="flex" flexDirection="row" style={{ alignItems: 'center' }}>
                            {whenToPost !== PostOption.PostNow.Name && (
                                <Stack direction="row" alignItems="center" sx={{ mr: 2 }}>
                                    <ErrorOutlineIcon sx={{ color: theme.palette.primary.main, mr: 0.5 }} />
                                    <Typography variant="caption" color="black">
                                        YOUR POST SCHEDULED ON
                                        <span
                                            style={{
                                                cursor: 'pointer',
                                                textDecoration: 'underline',
                                                color: theme.palette.primary.main,
                                                marginLeft: '4px',
                                            }}
                                        >
                                            {CommonHelper.formatDateAndTime(scheduleDateTime)}
                                        </span>
                                    </Typography>
                                </Stack>
                            )}
                            {whenToPost !== PostOption.PostNow.Name && (
                                <LocalizationProvider dateAdapter={AdapterDayjs} style={{ height: "30px" }}>
                                    <MobileDateTimePicker
                                        placeholder="Select Time"
                                        value={dayjs(scheduleDateTime)}
                                        onChange={handleScheduleDate}
                                        disabled={whenToPost === PostOption.PostNow.Name}
                                        style={{ height: "30px" }}
                                        renderInput={(props) => (
                                            <TextField
                                                {...props}
                                                size="small"
                                            />
                                        )}
                                        ToolbarComponent={CustomDateTimePickerToolbar}
                                    />
                                </LocalizationProvider>
                            )}
                            {/* <FormControlLabel
                                control={
                                    <NewPostStyledComponent.SwitchStyle
                                        sx={{ mr: 1, ml: 2 }}
                                        checked={whenToPost !== PostOption.PostNow.Name}
                                        onChange={handlePostTypeChange}
                                        disabled={!hasApproverPermission}
                                    />
                                }
                                label={whenToPost !== PostOption.PostNow.Name && <Typography variant="body1" color="black">Is Scheduled Post</Typography>}
                            /> */}
                            {/* <LoadingButton
                                variant="contained"
                                loading={isSubmitting}
                                endIcon={<SendIcon />}
                                onClick={handleFormsubmit}
                            >
                                {submitButtonView()}
                            </LoadingButton> */}
                            <ButtonGroup variant="contained" aria-label="Button group with a nested menu" sx={{ ml: 1 }}>
                                {submitButtonView()}
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                />
                                <Button
                                    onClick={handleMenuClick}
                                    size="small"
                                    aria-label="select merge strategy"
                                    aria-haspopup="menu"
                                    sx={{
                                        borderTopRightRadius: '7px',
                                        borderBottomRightRadius: 0,
                                    }}
                                >
                                    <ArrowDropDownIcon />
                                </Button>
                                <Popover
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                    transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                    PaperProps={{
                                        sx: {
                                            p: 1,
                                            width: 'auto',
                                            boxShadow: 3,
                                            borderRadius: 2,
                                            backdropFilter: 'blur(8px)',
                                            backgroundColor: 'action.disabledOpacity',
                                            boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
                                            '& .MuiMenuItem-root': {
                                                px: 2.5,
                                                py: 1.5,
                                                typography: 'body2',
                                                borderRadius: 2,
                                                transition: 'background-color 0.2s, transform 0.2s',
                                                display: 'flex',
                                                alignItems: 'center',
                                                '&:hover': {
                                                    backgroundColor: 'action.disabledOpacity',
                                                    transform: 'scale(1.02)',
                                                },
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: '1.5rem',
                                                    marginRight: 2,
                                                },
                                                '& .MuiTypography-root': {
                                                    fontWeight: 500,
                                                },
                                            },
                                        },
                                    }}
                                >

                                    <MenuItem
                                        disabled={!hasApproverPermission}
                                        value={PostOption.PostNow.Name}
                                        onClick={() => handleOptionSelect(PostOption.PostNow.Name)}
                                    >
                                        <SendIcon />
                                        {PostOption.PostNow.Label}
                                    </MenuItem>
                                    <MenuItem
                                        disabled={!hasApproverPermission}
                                        value={PostOption.SchedulePost.Name}
                                        onClick={() => handleOptionSelect(PostOption.SchedulePost.Name)}
                                    >
                                        <ScheduleIcon />
                                        {PostOption.SchedulePost.Label}
                                    </MenuItem>
                                    <MenuItem
                                        value={PostOption.ApprovalPost.Name}
                                        onClick={() => handleOptionSelect(PostOption.ApprovalPost.Name)}
                                    >
                                        <ApprovalIcon />
                                        {PostOption.ApprovalPost.Label}
                                    </MenuItem>

                                </Popover>
                            </ButtonGroup>
                        </Stack>
                    </Stack>
                </Card>}
        </>
    )
}
export default PostFooter;