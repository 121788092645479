import AccountBoxSharpIcon from '@mui/icons-material/AccountBoxSharp';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Box, Tab, Tabs, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { capitalCase } from 'change-case';
import { useState } from 'react';
import { Helmet } from "react-helmet-async";
import BrandInfoComponent from '../brand/brandInfo.component';
import ManageUsers from '../manage-users/manageUsers.component';
import PendingUsers from '../users/pendingUsers.component';
import ManageRoles from '../manage-roles/manageRoles.component';
import { CommonHelper } from 'src/utils/commonHelper';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

const ManageBrandInfo = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery('(max-width:599px)');
    const userPermissions = CommonHelper.GetBrandInfoFromLocalStorage("permissions");

    const [currentTab, setCurrentTab] = useState('Brand Info');
    
    
    const ACCOUNT_TABS = [
        {
            value: 'Brand Info',
            icon: <AccountBoxSharpIcon sx={{ width: "20px", height: "20px" }} />,
            component: <BrandInfoComponent />,
            permissions: [CommonHelper.PermissionList.READ_POST]
        },
        {
            value: 'Manage Roles',
            icon: <SupervisorAccountIcon sx={{ width: "20px", height: "20px" }} />, 
            component: <ManageRoles />,
            permissions: [CommonHelper.PermissionList.BRAND_ADMIN]
        },
        {
            value: 'Manage Users',
            icon: <ManageAccountsIcon sx={{ width: "20px", height: "20px" }} />,
            component: <ManageUsers />,
            permissions: [CommonHelper.PermissionList.BRAND_ADMIN]
        },
        {
            value: 'Pending Users',
            icon: <ManageAccountsIcon sx={{ width: "20px", height: "20px" }} />,
            component: <PendingUsers />,
            permissions: [CommonHelper.PermissionList.BRAND_ADMIN]
        },
    ];
    return (
        <>
            <Helmet>
                <title>Brand Management</title>
            </Helmet>
            <Box>
                <Tabs
                    value={currentTab}
                    scrollButtons="auto"
                    variant="scrollable"
                    sx={{ gap: '0.5rem', minHeight: '37px' }}
                    allowScrollButtonsMobile
                    onChange={(e, value) => setCurrentTab(value)}
                >
                    {ACCOUNT_TABS.map((tab) => {
                        let isShow = tab.permissions.some(b => userPermissions[b]);
                        return isShow && <Tab
                            disableRipple
                            key={tab.value}
                            label={isMobile ? "" : capitalCase(tab.value)}
                            icon={tab.icon}
                            value={tab.value}
                            sx={{ flexDirection: 'row', gap: '0.25rem', padding: 0, minHeight: '10px', mr: '0.75rem', fontWeight: 600 }}
                        />
                    })}
                </Tabs>

            </Box>
            <Box sx={{ mb: 2 }} />
            {ACCOUNT_TABS.map((tab) => {
                let isShow = tab.permissions.some(b => userPermissions[b]);
                const isMatched = tab.value === currentTab;
                return isShow && isMatched && <Box key={tab.value}>{tab.component}</Box>;
            })}
        </>
    );
};

export default ManageBrandInfo;
