import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import LinearProgressBar from 'src/components/progress-bar/progressBar.component';
import { CommonHelper } from 'src/utils/commonHelper';
import { useToaster } from 'src/utils/toaster/toasterContext';
import UploadImage from '../new-post-new/uploadFile';
import { PostUtil } from '../new-post-new/post-util';

const MediaUploadComponent = ({ handleMediaFiles, isUploadToImageServer = false }) => {

    const [fileUploadprogress, setFileUploadprogress] = useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery('(max-width:599px)');
    const maxFileSizeInBytes = 1073741824; // 1 GB
    const { showToast } = useToaster();

    const fileUploadSuccessCallback = (data, message) => {
        message && showToast(message, "s");
        let mediaData = data && data.media_data;
        if (mediaData && mediaData.length > 0) {
            let uploadedFiles = mediaData.map(uploadedData => {
                return {
                    media_url: uploadedData.media_url,
                    thumbnail_urls: uploadedData.thumbnail_urls,
                    aspect_ratio: uploadedData.aspect_ratio,
                    video_length: uploadedData.video_length,
                    media_type: uploadedData.media_type
                };
            });
            handleMediaFiles([...uploadedFiles]);
        }
        else {
            showToast("Something went wrong. Please contact admin", "e");
        }
    }
    const failureCallback = (message) => {
        message && showToast(message, "e");
    }
    const handleImages = (files) => {
        let supportedFiles = files.filter(a => PostUtil.SupportedFileFormat.includes(a.type));
        if (supportedFiles.length === files.length || supportedFiles.length > 0) {
            if (!files.some(a => a.size > maxFileSizeInBytes)) {
                if (isUploadToImageServer) {
                    let uploadFilesProps = {
                        files,
                        setFileUploadprogress,
                        successCallback: fileUploadSuccessCallback,
                        failureCallback
                    };
                    CommonHelper.UploadMediaFilesToServer({ ...uploadFilesProps });
                }
                else {
                    handleMediaFiles(files);
                }
            }
            else {
                showToast("Maximum file size for video/image is 1GB. Please select video/image below 1 GB", "e");
            }
        }
        else {
            let errorMessage = "We only support JPEG, PNG & MP4 file formats.";
            if (files.length > 1) {
                errorMessage = errorMessage + "One of your selected files are not in the format";
            }
            else {
                errorMessage = errorMessage + "We only support JPEG, PNG & MP4 file formats. The selected file are not in the supported format";
            }
            showToast(errorMessage, "e");
        }
    };
    return (
        <>
            <UploadImage
                name="images"
                accept={['image/*', 'video/*']}
                onDrop={handleImages}
                isMobile={isMobile}
            />
            {fileUploadprogress > 0 && <LinearProgressBar value={fileUploadprogress} />}
        </>
    );
}
export default MediaUploadComponent;