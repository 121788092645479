import { styled } from '@mui/material/styles';
import { AppBar, Avatar, Toolbar, Typography, Switch, Box } from '@mui/material';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';

const NAV_WIDTH = 300;
const HEADER_MOBILE = 42;
const HEADER_DESKTOP = 40;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'white',
  color: 'black',
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
  // placement: 'bottom-start'
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 0),
  },
}));

const SmallAvatarChild = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  overflowY: 'auto',
  backgroundColor: '#17A6FC',
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 16,
  height: 16,
  backgroundColor: '#fff',
}));

const Instagram_Icon = styled(Avatar)(({ theme }) => ({
  width: 30,
  height: 30,
}));

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

const AccordionStyle = {
  borderRadius: '5px',
  boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
  border: '0.1rem solid',
};

const AccordionSummary = {
  backgroundColor: '#f4f6f8', color: '#637381',
  borderRadius: '5px',
  minHeight: '44px !important',
  margin: '0 !important',
  '.MuiAccordionSummary-contentGutters': {
    margin: '0 !important',
  },
};

const AccordianLabelStyles = styled(Box)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 700,
  lineHeight: 1.5,
  textTransform: 'uppercase'
}));

const VideoPlayStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1,
  fontSize: '50px',
  borderRadius: '50%',
  background: 'black',
  overflow: 'hidden',
  objectFit: 'cover',
  color: 'white'
};

const SwitchStyle = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : theme.palette.primary.main,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
const MobileDatePickerStyle = styled(MobileDateTimePicker)(({ theme }) => ({
  '& .MuiInputBase-input': {
    paddingTop: 10,
    paddingBottom: 10
  },
  '& .MuiOutlinedInput-input': {
    paddingTop: 10,
    paddingBottom: 10
  }
}));

export const NewPostStyledComponent = {
  StyledRoot,
  StyledToolbar,
  SmallAvatarChild,
  SmallAvatar,
  AccordionStyle,
  AccordionSummary,
  AccordianLabelStyles,
  Instagram_Icon,
  LabelStyle,
  VideoPlayStyle,
  SwitchStyle,
  MobileDatePickerStyle
}