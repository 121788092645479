import { useTheme } from '@emotion/react';
import { Hidden, TableBody, useMediaQuery, ListItemText,Tabs,Box,Tab } from '@mui/material';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import { CommonHelper } from 'src/utils/commonHelper';
import { useState } from 'react';
import { PostDetailsHelper } from 'src/utils/postDetailsHelper';
import { useToaster } from 'src/utils/toaster/toasterContext';
import PendingApprovaldGridAction from './pending-approval-grid-action.component';
import { AccountBoxSharp as AccountBoxSharpIcon } from '@mui/icons-material';
import PostPreview from 'src/pages/scheduledPost/Preview/post-preview.component';
import PostReviewComments from 'src/pages/scheduledPost/Preview/post-preview-comment.component';
import { useRightSidebar } from 'src/layouts/right-side-bar/rightSidebarProvider';

const PendingApprovalGridBody = ({ pendingApprovalList, page, rowsPerPage, handleReCallPendingApprovalPost }) => {
  const theme = useTheme();
  let maxWidth = useMediaQuery('(max-width:599px)') ? 500 : 400;
  const isMobile = useMediaQuery('(max-width:599px)');
  const POPUP_WIDTH = isMobile ? '300px' : '500px';
  const { showToast } = useToaster();
  const handleOpenScheduledPost = (row, open = false) => {
    let data = {
      content: postView,
      data: row,
    };
    showRightSidebar(data, POPUP_WIDTH);
  };

  const postView = (row) => {
    let postDetails = {
        data: { id: row },
    };
    return <PublicDetailsPreview postDetails={postDetails} />;
};
  const ACCOUNT_TABS = (postDetails) => [
    {
        value: 'Preview Event',
        icon: <AccountBoxSharpIcon sx={{ width: 25, height: 25, mb: '0 !important' }} />,
        component: <PostPreview postDetails={postDetails}  />,
    },
    {
        value: 'Review Comments',
        icon: <AccountBoxSharpIcon sx={{ width: 25, height: 25, mb: '0 !important' }} />,
        component: <PostReviewComments PostDetails={postDetails} />,
    }
];

  const PublicDetailsPreview = ({ postDetails }) => {
    const tabs = ACCOUNT_TABS(postDetails);
    const [currentTab, setCurrentTab] = useState(tabs[0].value);

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    return (
        <Box>
            <Tabs
                value={currentTab}
                onChange={handleChange}
                scrollButtons="auto"
                variant="scrollable"
                sx={{ gap: '0.5rem', minHeight: '37px' }}
                allowScrollButtonsMobile
            >
                {tabs.map((tab) => (
                    <Tab
                        key={tab.value}
                        value={tab.value}
                        label={tab.value}
                        icon={tab.icon}
                        sx={{
                            flexDirection: 'row',
                            gap: '0.25rem',
                            padding: 1,
                            minHeight: '10px',
                            mr: '0.75rem',
                            ml: '0.75rem',
                            fontWeight: 600,
                        }}
                        disableRipple
                    />
                ))}
            </Tabs>
            <Box sx={{ mb: 2 }} />
            <Box>
                {tabs.find((tab) => tab.value === currentTab)?.component}
            </Box>
        </Box>
    );
};

  const { showRightSidebar } = useRightSidebar();

  const toggleScheduledPostSuccessCallback = (data, message) => {
    showToast(message, 's');
    handleReCallPendingApprovalPost();
  };

  const toggleScheduledPostFailureCallback = (message) => {
    showToast(message, 'e');
    handleReCallPendingApprovalPost();
  };

  return (
    <>
      <TableBody>
        {pendingApprovalList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
          return (
            <>
              <StyledComponent.StyledTableRow
                onClick={() => {
                  handleOpenScheduledPost(row.id, true);
                }}
              >
                <StyledComponent.StyledTableCell>
                  {CommonHelper.getTableDateAndTime(row.scheduled_time)}
                </StyledComponent.StyledTableCell>
                <Hidden only={['xs']}>
                  <StyledComponent.StyledTableCell>
                    <ListItemText
                      sx={{
                        m: 0,
                        '.MuiListItemText-primary': {
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontSize: '0.875rem',
                        },
                        '.MuiListItemText-secondary': {
                          color: '#637381',
                          fontSize: '0.75rem',
                          textTransform: 'lowercase',
                        },
                      }}
                      primary={CommonHelper.formatDateAndTime(row.created_time, 'date')}
                      secondary={CommonHelper.formatDateAndTime(row.created_time, 'time')}
                    />
                  </StyledComponent.StyledTableCell>
                </Hidden>
                <Hidden only={['xs']}>
                  <StyledComponent.StyledTableCell>
                    <ListItemText
                      sx={{
                        m: 0,
                        '.MuiListItemText-primary': {
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontSize: '0.875rem',
                        },
                        '.MuiListItemText-secondary': {
                          color: '#637381',
                          fontSize: '0.75rem',
                          textTransform: 'lowercase',
                        },
                      }}
                      primary={CommonHelper.formatDateAndTime(row.updated_at, 'date')}
                      secondary={CommonHelper.formatDateAndTime(row.updated_at, 'time')}
                    />
                  </StyledComponent.StyledTableCell>
                </Hidden>
                <StyledComponent.StyledTableCell>
                  {PostDetailsHelper.GetPostContent(row.description, maxWidth)}
                </StyledComponent.StyledTableCell>
                <Hidden only={['xs']}>
                  <StyledComponent.StyledTableCell>
                    {PostDetailsHelper.getPostMedias(row.media_url)}
                  </StyledComponent.StyledTableCell>
                </Hidden>
                <StyledComponent.StyledTableCell>
                  {PostDetailsHelper.getProfile(row.created_by.name, row.created_by.email, row.created_by.profile_pic)}
                </StyledComponent.StyledTableCell>
                <Hidden only={['xs']}>
                  <StyledComponent.StyledTableCell>
                    {PostDetailsHelper.getSocialMediaViewWithTooltip(row.connected_profile_details)}
                  </StyledComponent.StyledTableCell>
                </Hidden>
                <StyledComponent.StyledTableCell align='center'  onClick={(event) => event.stopPropagation()}>
                  <PendingApprovaldGridAction
                    approvePost={row}
                    handleReCallPendingApprovalPost={handleReCallPendingApprovalPost}
                  />
                </StyledComponent.StyledTableCell>
              </StyledComponent.StyledTableRow>
            </>
          );
        })}
      </TableBody>
    </>
  );
};

export default PendingApprovalGridBody;
