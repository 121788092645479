import { LoadingButton } from '@mui/lab';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    TextField,
    Grid,
    Chip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CommonHelper } from 'src/utils/commonHelper';
import Spinner from 'src/utils/Loader/spinner';
import NoPostFound from 'src/pages/publishedPosts/noPost.component';
import SummaryCommonCard from 'src/components/reports/summary-cards/summaryCommonCard';
import { useToaster } from 'src/utils/toaster/toasterContext';
import HttpServices from 'src/services/httpService';
import { reportModuleURL } from 'src/services/urlService';
import DonutChart from 'src/components/reports/summary-cards/summaryDonutCard';
import BestPostCardDetails from 'src/components/reports/summary-cards/bestPostCard';
import NoReportCard from 'src/components/reports/summary-cards/noReportCard';
import TableDonutChart from 'src/components/reports/summary-cards/summaryTableDonutCard';

const AudienceSummaryComponent = ({ instagramPageId, startDate, endDate, viewBy,audienceSummary }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery('(max-width:599px)');
    const navigate = useNavigate();


    const [isLoading, setloading] = useState(true);
    const [instagramAudienceSummary, setInstagramAudienceSummary] = useState({
        totalFollowers: null,
        newFollowers: null,
    });

    const [height, setHeight] = useState(window.innerHeight - 150);
    //Table Variables
    const { showToast } = useToaster();

    useEffect(() => {
        instagramPageId && getInstagramAudienceSummary();
    }, [instagramPageId, startDate, endDate]);
    const getInstagramAudienceSummary = () => {
        setloading(true);
        let body = {
            connected_profile_id: instagramPageId,
            report_id: audienceSummary.id,
            date_range: {
                from: startDate,
                to: endDate,
            },
            view_by: "day"
        }
        let getProps = {
            url: reportModuleURL.instagramAudienceSummary,
            body: body,
            successCallback: instagramPublishedPostSuccessCallback,
            failureCallback: instagramPublishedPostFailureCallback
        }
        HttpServices.Post(getProps);
    };


    const instagramPublishedPostFailureCallback = (message) => {
        setInstagramAudienceSummary({
            totalFollowers: null,
            newFollowers: null,
        });
        message && showToast(message, "e");
        setloading(false);
    };
    const instagramPublishedPostSuccessCallback = (data, message) => {
        const responseData = data[0].data;
        setInstagramAudienceSummary({
            totalFollowers: responseData.total_followers,
            newFollowers: responseData.new_followers,
        });
        setloading(false);
    };
    return (
        <Card>
            {isLoading ?
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3} >
                        <NoReportCard />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                        <NoReportCard />
                    </Grid>

                </Grid>
                :
                    <Grid container spacing={2}>
                        {instagramAudienceSummary.totalFollowers && instagramAudienceSummary.totalFollowers.card_type != 'no-report' ? (
                            <Grid item xs={12} sm={6} md={3} >
                                <SummaryCommonCard
                                    title={instagramAudienceSummary.totalFollowers.title}
                                    data={instagramAudienceSummary.totalFollowers.data}
                                    percentage={instagramAudienceSummary.totalFollowers.percentage}
                                    percentageState={instagramAudienceSummary.totalFollowers.percentage_state}
                                    averageData={instagramAudienceSummary.totalFollowers.average_data}
                                />
                            </Grid>
                        ) : (
                            <Grid item xs={12} sm={6} md={3}>
                                <NoReportCard />
                            </Grid>
                        )}

                        {instagramAudienceSummary.newFollowers && instagramAudienceSummary.newFollowers.card_type != 'no-report' ? (
                            <Grid item xs={12} sm={6} md={3}>
                                <SummaryCommonCard
                                    title={instagramAudienceSummary.newFollowers.title}
                                    data={instagramAudienceSummary.newFollowers.data}
                                    percentage={instagramAudienceSummary.newFollowers.percentage}
                                    percentageState={instagramAudienceSummary.newFollowers.percentage_state}
                                    averageData={instagramAudienceSummary.newFollowers.average_data}
                                />
                            </Grid>
                        ) : (
                            <Grid item xs={12} sm={6} md={3}>
                                <NoReportCard />
                            </Grid>
                        )}

                    </Grid>
            }
        </Card>
    );
};
export default AudienceSummaryComponent;
