import React, { useState, useEffect, useRef } from 'react';
import { Box, Stack,TextField, Typography, Avatar, styled, IconButton, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useToaster } from 'src/utils/toaster/toasterContext';
import { useTheme } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import HttpServices from 'src/services/httpService';
import { postsModuleURL } from 'src/services/urlService';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import { CommonHelper } from 'src/utils/commonHelper';
import TextIconLabel from 'src/components/TextIconLabel';
import ReplyRoundedIcon from '@mui/icons-material/ReplyRounded';
import Spinner from 'src/utils/Loader/spinner';
import Label from 'src/components/Label';

const PostReviewComments = ({ PostDetails}) => {
    const fileInputRef = useRef(null);
    const [postDetails, setPostDetails] = useState(null);
    const [commentData, setCommentData] = useState([]);
    const [comment, setComment] = useState('');
    const [isLoading, setLoading] = useState(false);
    const { showToast } = useToaster();
    const [showLoader, setLoader] = useState(true);
    const theme = useTheme();
    const [reply, setReply] = useState('');
    const [activeCommentId, setActiveCommentId] = useState(null);
    const isMobile = useMediaQuery('(max-width:599px)');
    const sideBarWidth = isMobile ? '300px' : '500px';
    const publicCommentorName = CommonHelper.GetBrandInfoFromLocalStorage("user_details")?.first_name;

    useEffect(() => {
        getPostDetails();
    }, []);

    const getPostDetails = () => {
        setLoader(true);
        let getProps = {
            url: `${postsModuleURL.scheduledEventInfo}${PostDetails.data.id}`,
            successCallback: facebookPostDetailsSuccessCallback,
            failureCallback: facebookPostDetailsFailureCallback,
        };
        HttpServices.Get(getProps);
    };

    const facebookPostDetailsSuccessCallback = (data) => {
        const commentsWithReplies = categorizeComments(data.comments);
        setCommentData(commentsWithReplies);
        setLoader(false);
    };
    const categorizeComments = (comments) => {
        const commentMap = new Map();
        const structuredComments = [];
    
        comments.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at));

        comments.forEach(comment => {
            comment.replies = [];
            commentMap.set(comment.id, comment);
        });

        comments.forEach(comment => {
            if (comment.parent_comment_id === -1) {
                structuredComments.push(comment);
            } else {
                const parentComment = commentMap.get(comment.parent_comment_id);
                if (parentComment) {
                    parentComment.replies.push(comment);
                }
            }
        });

        return structuredComments;
    };


    const facebookPostDetailsFailureCallback = (message) => {
        showToast(message, 'e');
        setLoader(false);
    };

    // Handle comment post
    const handlePostComment = () => {
        setLoading(true);
        let postCommentBody = {
            schedule_post_id: PostDetails.data.id,
            comment_description: comment,
            name: publicCommentorName,
        };

        let postProps = {
            url: postsModuleURL.addCommentToScheduledEventInfo ,
            body: postCommentBody,
            successCallback: () => {
                showToast('Comment added successfully', 's');
                setComment('');
                setLoading(false);
                getPostDetails();
            },
            failureCallback: (message) => {
                setLoading(false);
                showToast(message, 'e');
            },
        };

        HttpServices.Post(postProps);
    };

    const handlePostReply = (commentId) => {
        setLoading(true);

        const replyData = {
            schedule_post_id: PostDetails.data.id,
            comment_id: commentId,
            comment_description: reply,
            name: publicCommentorName,
        };

        let postProps = {
            url: postsModuleURL.addReplyCommentToScheduledEventInfo,
            body: replyData,
            successCallback: () => {
                showToast('Reply added successfully', 's');
                setReply('');
                setActiveCommentId(null);
                setLoading(false);
                getPostDetails();
            },
            failureCallback: (message) => {
                setLoading(false);
                showToast(message, 'e');
            },
        };

        HttpServices.Post(postProps);
    };
    const handleKeyUp = (event,comment) => {
        if (event.key === 'Enter' && comment !== "") {
            handlePostComment(comment);
        }
      };
      const handleKeyUpReply = (event,reply,comment_id) => {
        if (event.key === 'Enter' && reply !== "") {
            handlePostReply(comment_id);
        }
      };

    // Render comments and replies
    const renderComments = (comments) => {
        if (!Array.isArray(comments)) {
            return <Typography>No comments available</Typography>;
        }
        return comments.map((comment) => (
            <Box key={comment.id}>
                <Stack direction="row" spacing={2} style={{ marginLeft: comment?.parent_comment_id < 0 && 15, marginRight: comment?.parent_comment_id < 0 && 15, marginTop: 5, marginBottom: 15 }}>
                    <Avatar src={comment?.public_commentor_name ? comment?.public_commentor_name : comment?.commentor_profile_pic} alt={comment?.public_commentor_name ? comment?.public_commentor_name : comment.id}
                        sx={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            height: 40,
                            width: 40,
                            bgcolor: theme.palette.primary.main,
                        }} />
                    <Box sx={{ p: 1.5, flexGrow: 1, bgcolor: 'background.neutral', boxShadow: 3, borderRadius: '10px' }}>
                        <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                            <Stack>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Typography variant="subtitle2">{comment?.public_commentor_name || comment?.commentor_name}</Typography>
                                    {comment?.is_public && <Label color="success">
                                        Public
                                    </Label>}
                                </Stack>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    {comment?.comment_description}
                                </Typography>
                            </Stack>
                            <Typography variant="caption" sx={{ color: 'text.disabled', alignSelf: 'flex-start' }}>
                                {CommonHelper.DateTimeFromCurrentTime(comment?.updated_at)}
                            </Typography>
                        </Stack>
                        {comment?.parent_comment_id < 0 &&
                            <Stack direction="row" justifyContent="flex-end" sx={{ color: 'text.disabled' }}>
                                <StyledIconButton onClick={() => setActiveCommentId(comment?.id)}>
                                    <ReplyRoundedIcon sx={{ transform: 'rotateY(180deg)', width: 20, height: 20 }} />
                                </StyledIconButton>
                            </Stack>
                        }

                        {/* Render reply input if this is the active comment */}
                        {activeCommentId === comment?.id && comment?.parent_comment_id < 0 && (
                            <Stack direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={1} sx={{ mb: comment?.parent_comment_id < 0 && 2 }}>
                                <Stack direction="column" sx={{ width: '100%' }}>
                                    <Stack direction="row" alignItems="center" >
                                        <Typography sx={{ fontWeight: 700 }}>Name: {publicCommentorName}</Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" sx={{ mt: 1 }}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            value={reply}
                                            onKeyUp={(e)=>{
                                                handleKeyUpReply(e,reply,comment.id)}
                                              }
                                            placeholder="Write a reply…"
                                            onChange={(e) => setReply(e.target.value)}
                                        />
                                        <LoadingButton
                                            loading={isLoading}
                                            endIcon={<SendIcon />}
                                            onClick={() => handlePostReply(comment.id)}
                                            disabled={!reply}
                                            sx={{ minWidth: 40, height: 40, border: 'none' }}
                                        />
                                        <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
                                    </Stack>
                                </Stack>
                            </Stack>
                        )}

                        {/* Render replies */}
                        {comment?.replies && (
                            <>
                                {renderComments(comment?.replies)}
                            </>
                        )}
                    </Box>
                </Stack >
            </Box >
        ));
    };

    const postComments = () => {
        return (
            <Stack direction="row" alignItems="flex-start" justifyContent="flex-start" >
                <Stack direction="column" sx={{ width: '100%' }}>
                    <Stack direction="row" alignItems="center">
                        <Typography sx={{ fontWeight: 700 }}>Name: {publicCommentorName}</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                        <TextField
                            fullWidth
                            size="small"
                            value={comment}
                            onKeyUp={(e)=>{
                                handleKeyUp(e,comment)}
                              }
                            placeholder="Write a comment…"
                            onChange={(e) => setComment(e.target.value)}
                        />
                        <LoadingButton
                            loading={isLoading}
                            endIcon={<SendIcon />}
                            onClick={handlePostComment}
                            disabled={!comment}
                            sx={{ minWidth: 40, height: 40, 
                                border: 'none', '&.Mui-disabled': {
                                    border: 'none',
                                    opacity: 0.6
                                  },
                                  '&.MuiLoadingButton-root:hover': {
                                    border: 'none',
                                    background: 'none'
                                  }
                                 }}
                        />
                        <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
                    </Stack>
                </Stack>
            </Stack>
        )
    }

    // Styled Components
    const StyledIconButton = styled(IconButton)(({ theme }) => ({
        '& .MuiSvgIcon-root': {
            [theme.breakpoints.up('sm')]: {
                fontSize: 20,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: 18,
            },
        },
    }));

    return (
        <>
            <Box>
                {showLoader ? (
                    <Spinner />
                ) : (
                    <>
                        <Box sx={{ mb: 12 }}>
                            {renderComments(commentData)}
                        </Box>
                        <Box sx={{ position: 'fixed', bottom: '0px', width: sideBarWidth, background: 'white', padding: '15px' }}>
                            {postComments()}
                        </Box>
                    </>
                )}
            </Box>
        </>
    );
};

export default PostReviewComments;