import {
  Avatar, Badge, Card, IconButton, Paper, Stack, Table, TableBody,
  TableCell, TableContainer, TablePagination, TableRow, Typography
} from '@mui/material';
import Hidden from '@mui/material/Hidden';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/system';
import { useEffect, useState } from 'react';
import Label from 'src/components/Label';
import HttpServices from 'src/services/httpService';
import { integrateModuleURL } from 'src/services/urlService';
import { useToaster } from 'src/utils/toaster/toasterContext';
import Facebook from '../../assets/facebook.svg';
import Instagram from '../../assets/instagram.svg';
import GBusiness from '../../assets/google-my-business-logo.svg';
import Pinterest_Icon from '../../assets/pinterest-icon.svg';
import Twitter from '../../assets/twitter.svg';
import YouTube from '../../assets/youtube_social_icon_red 128px.png';
import LinkedIn from '../../assets/linked_in.svg';
import Tiktok from '../../assets/tiktok.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Scrollbar from '../../components/scrollbar';
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import { StyledComponent } from 'src/components/styled-components/styledComponent';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import IntegratedActionPopup from './integratedActionPopup';
import FacebookReAuthenticate from './re-authenticate-accounts/facebookReAuthenticate';
import InstagramReAuthenticate from './re-authenticate-accounts/instagramReAuthenticate';
import YouTubeReAuthenticate from './re-authenticate-accounts/youtubeReAuthenticate';
import LinkedInPageReAuthenticate from './re-authenticate-accounts/linkedinPageReAuthenticate';
import LinkedInProfileReAuthenticate from './re-authenticate-accounts/linkedProfileReAuthenticate';
import TiktokReAuthenticate from './re-authenticate-accounts/tiktokReAuthenticate';
import TwitterReAuthenticate from './re-authenticate-accounts/twitterReAuthenticate';
import GoogleMyBusinessReAuthenticate from './re-authenticate-accounts/googlemybusinessReAuthenticate';
import DynamicHeightComponent from 'src/utils/dynamicSize.component';
import { CommonHelper } from 'src/utils/commonHelper';
import TwitterDeveloperReAuthenticate from './re-authenticate-accounts/twitterDeveloperReAuthenticate';
import PinterestReAuthorizate from './re-authenticate-accounts/pinterestReAuthenticate';

const IntegratdAccounts = () => {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterName, setFilterName] = useState('');
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [integratedAccountData, setIntegratedAccountData] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [toBeDeletedId, setToBeDeletedId] = useState(null);
  const [toBeUpdate, setToBeUpdate] = useState(null);
  const [popoverElement, setPopoverElement] = useState(null);
  const [selectedPopOverAccount, setPopOverAccount] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isNotFound, setIsNotFound] = useState(true);
  const { showToast } = useToaster();
  const [facebookPageReAuthenticateOpen, setfacebookPageReAuthenticateOpen] = useState(false);
  const [instagramReAuthenticateOpen, setInstagramReAuthenticateOpen] = useState(false);
  const [youtubeReAuthenticateOpen, setYoutubeReAuthenticateOpen] = useState(false);
  const [linkedinPageReAuthenticateOpen, setLinkedinPageReAuthenticateOpen] = useState(false);
  const [linkedinProfileReAuthenticateOpen, setlinkedinProfileReAuthenticateOpen] = useState(false);
  const [googleMyBusinessReAuthenticateeOpen, setGoogleMyBusinessReAuthenticateOpen] = useState(false);
  const [twitterReAuthenticateOpen, setTwitterReAuthenticateOpen] = useState(false);
  const [tiktokReAuthenticateOpen, setTiktokReAuthenticateOpen] = useState(false);
  const [twitterDeveloperReAuthenticateOpen, setTwitterDeveloperReAuthenticateOpen] = useState(false);
  const [pinterestReAuthenticateOpen, setPinterestReAuthenticateOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:599px)');
  const responseHeight =  isMobile ? 'calc(100vh - 335px)' : 'calc(100vh - 315px)'
  const getTableDesk = [
    { id: 'profile_name', label: 'Name', alignRight: false },
    { id: 'service_name', label: 'Social Media', alignRight: false },
    { id: 'profile_type', label: 'Type', alignRight: false },
    { id: 'token_status', label: 'Token Status', alignRight: false },
    { id: 'account_status', label: 'Account Status', alignRight: false },
    { id: 'action', label: 'Action', alignRight: true },
  ];
  const getTableMobile = [
    { id: 'profile_name', label: 'Name', alignRight: false },
    { id: 'account_status', label: 'Status', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false },
  ];
  const TABLE_HEAD = isMobile ? getTableMobile : getTableDesk;

  useEffect(() => {
    getConnectedNetworks();
  }, []);

  const connectedNetworksSuccessCallback = (data, message) => {
    setIntegratedAccountData(data);
    setFilteredAccounts(data);
    setIsNotFound(data && data.length === 0);
  };

  const connectedNetworksFailureCallback = (message) => {
    showToast(message, 'e');
  };

  const getConnectedNetworks = () => {
    let getProps = {
      url: integrateModuleURL.getConnectedNetworks,
      successCallback: connectedNetworksSuccessCallback,
      failureCallback: connectedNetworksFailureCallback
    };
    HttpServices.Get(getProps);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    filteredAccounts.sort((a, b) => {
      return a;
    });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked)
      setSelectedAccounts([...filteredAccounts]);
    else
      setSelectedAccounts([]);
  };

  const handleClick = (event, selectedAccount) => {
    let accounts = [...selectedAccounts];
    if (event.target.checked) {
      accounts.push(selectedAccount);
    } else {
      let index = accounts.findIndex(a => a.id === selectedAccount.id);
      if (index >= 0)
        accounts.splice(index, 1);
    }
    setSelectedAccounts(accounts);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    const filterName = event.target.value.toLowerCase();
    setFilterName(filterName);
    const filteredData = integratedAccountData.filter((account) =>
      account.profile_name.toLowerCase().includes(filterName)
    );
    setFilteredAccounts(filteredData);
    setIsNotFound(filteredData.length === 0);
    setPage(0);
  };

  const disIntegrateSuccessCallback = (data, message) => {
    getConnectedNetworks();
    showToast(message, 's');
    setConfirmOpen(false)
    handleCloseMenu();
  };

  const disIntegrateFailureCallback = (message) => {
    showToast(message, 'e');
    setConfirmOpen(false)
    handleCloseMenu();
  };

  const handleDisAntegrateAccount = () => {
    let bodyProps = {
      accounts: [{
        id: toBeDeletedId?.id,
        service_id: toBeDeletedId?.service_id,
      }],
    };
    let postProps = {
      url: integrateModuleURL.disintegrateAccounts,
      body: bodyProps,
      successCallback: disIntegrateSuccessCallback,
      failureCallback: disIntegrateFailureCallback
    };
    HttpServices.Post(postProps);
  };

  const handleFacebookPage = (show = false) => {
    setfacebookPageReAuthenticateOpen(show);
    if (!show) {
      handleCloseMenu();
    }
  };
  const handleInstagram = (show = false) => {
    setInstagramReAuthenticateOpen(show);
    if (!show) {
      handleCloseMenu();
    }
  };
  const handleYoutube = (show = false) => {
    setYoutubeReAuthenticateOpen(show);
    if (!show) {
      handleCloseMenu();
    }
  };
  const handleLinkedPage = (show = false) => {
    setLinkedinPageReAuthenticateOpen(show);
    if (!show) {
      handleCloseMenu();
    }
  };
  const handleLinkedProfile = (show = false) => {
    setlinkedinProfileReAuthenticateOpen(show);
    if (!show) {
      handleCloseMenu();
    }
  };
  const handlePinterest = (show = false) => {
    setPinterestReAuthenticateOpen(show);
    if (!show) {
      handleCloseMenu();
    }
  };
  const handleGoogleMyBusiness = (show = false) => {
    setGoogleMyBusinessReAuthenticateOpen(show);
    if (!show) {
      handleCloseMenu();
    }
  };
  const handleTiktok = (show = false) => {
    setTiktokReAuthenticateOpen(show);
    if (!show) {
      handleCloseMenu();
    }
  };
  const handleTwitter = (isDeveloper, show = false) => {
    if (isDeveloper) {
      setTwitterDeveloperReAuthenticateOpen(show);
    }
    else {
      setTwitterReAuthenticateOpen(show);
    }

    if (!show) {
      handleCloseMenu();
    }
  };
  const handleCloseTwitter = () => {
    setTwitterDeveloperReAuthenticateOpen(false);
    setTwitterReAuthenticateOpen(false);
  }
  const handleDelete = (selectedAccount) => {
    setToBeDeletedId(selectedAccount);
    setConfirmOpen(true);
  };
  const handleUpdateAccount = (selectedAccount) => {
    let fullurl = "";
    let bodyProps = {
      account_id: selectedAccount?.id
    }
    switch (selectedAccount.service_name) {
      case 'twitter':
        fullurl = integrateModuleURL.updateTwitterAccounts;
        break;
      case 'facebook':
        fullurl = integrateModuleURL.updateFacebookAccounts;
        break;
      case 'youtube':
        fullurl = integrateModuleURL.updateYoutubeAccounts;
        break;
      case 'instagram':
        fullurl = integrateModuleURL.updateInstagramAccounts;
        break;
      case 'linkedin':
        fullurl = integrateModuleURL.updateLinkedinAccounts;
        bodyProps.profile_type = selectedAccount.profile_type;
        break;
      case 'gbusiness':
        fullurl = integrateModuleURL.updateGbusinessAccounts;
        break;
      case 'pinterest':
        fullurl = integrateModuleURL.updatePinterestAccounts;
        break;
      case 'tiktok':
        fullurl = integrateModuleURL.updateTiktokAccounts;
        break;
      default:
        return null;
    }
    let postProps = {
      url: fullurl,
      body: bodyProps,
      successCallback: disIntegrateSuccessCallback,
      failureCallback: disIntegrateFailureCallback
    };
    HttpServices.Post(postProps);
  }
  const handleReAuthenticate = (selectedAccount) => {

    switch (selectedAccount.service_name) {
      case 'facebook':
        handleFacebookPage(true);
        break;
      case 'instagram':
        handleInstagram(true);
        break;
      case 'youtube':
        handleYoutube(true);
        break;
      case 'gbusiness':
        handleGoogleMyBusiness(true);
        break;
      case 'tiktok':
        handleTiktok(true);
        break;
      case 'twitter':
        handleTwitter(selectedAccount.profile_type === "DEVELOPER", true);
        break;
      case 'pinterest':
        handlePinterest(true);
        break;
      case 'linkedin':
        if (selectedAccount.profile_type === "PAGE") {
          handleLinkedPage(true);
        }
        else {
          handleLinkedProfile(true);
        }
        break;
      default:
        return null;
    }
  }
  const handleViewLink = (selectedAccount) => {
    if (selectedAccount?.profile_link) {
      window.open(selectedAccount.profile_link, "_blank");
    }
    handleCloseMenu();
  };

  const getAvatar = (service_name) => {
    switch (service_name) {
      case 'twitter':
        return <SmallAvatar_t alt="twitter" src={Twitter} />;
      case 'facebook':
        return <SmallAvatar_f alt="facebook" src={Facebook} />;
      case 'youtube':
        return <img alt="youtube" src={YouTube} />;
      case 'pinterest':
        return <img alt="youtube" src={Pinterest_Icon}/>;
      case 'instagram':
        return <SmallAvatar_i alt="instagram" src={Instagram} />;
      case 'gbusiness':
        return <SmallAvatar_g alt="gbusiness" src={GBusiness} variant="rounded" />;
      case 'linkedin':
        return <SmallAvatar_l alt="linkedin" src={LinkedIn} variant="rounded" />;
      case 'tiktok':
        return <SmallAvatar_l alt="linkedin" src={Tiktok} />;
      default:
        return null;
    }
  };

  const handleAccountStatus = (row) => {
    let bodyProps = {
      accounts: [{
        id: row?.id,
        service_id: row?.service_id,
      }],
    };
    let postProps = {
      url: row.is_enabled ? integrateModuleURL.disableSocialAccounts : integrateModuleURL.enableSocialAccounts,
      body: bodyProps,
      successCallback: disIntegrateSuccessCallback,
      failureCallback: disIntegrateFailureCallback
    };
    HttpServices.Post(postProps);
  };

  const handleOpenMenu = (event, row) => {
    setPopOverAccount(row);
    setPopoverElement(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setPopOverAccount(null);
    setPopoverElement(null);
  };

  //Styled Components
  const SmallAvatar_f = styled(Avatar)(({ }) => ({
    width: 20,
    height: 20,
    overflow: 'hidden',
    backgroundColor: '#ffffff',
  }));

  const SmallAvatar_i = styled(Avatar)(({ }) => ({
    width: 20,
    height: 20,
    overflow: 'hidden',
  }));
  const SmallAvatar_g = styled(Avatar)(({ }) => ({
    width: 23,
    height: 23,
    overflow: 'hidden',
  }));

  const SmallAvatar_t = styled(Avatar)(({ }) => ({
    width: 20,
    height: 20,
    backgroundColor: '#ffffff',
  }));

  const SmallAvatar_l = styled(Avatar)(({ }) => ({
    width: 23,
    height: 23,
    overflow: 'hidden',
  }));

  const postActionProps = {
    popoverElement: popoverElement,
    handleCloseMenu,
    handleUpdateAccount: () => handleUpdateAccount(selectedPopOverAccount),
    handleDeletePost: () => handleDelete(selectedPopOverAccount),
    handleReAuthenticate: () => handleReAuthenticate(selectedPopOverAccount),
    handleViewLink: () => handleViewLink(selectedPopOverAccount),
    selectedPopOverPost: selectedPopOverAccount,
  };

  return (
    <>
      <Card sx={{ mt: isMobile ? 2 : 3 }}>
        <UserListToolbar numSelected={selectedAccounts.length} filterName={filterName} onFilterName={handleFilterByName} handleDelete={handleDelete} />
        <DynamicHeightComponent >
          <Scrollbar>
            <TableContainer sx={{ minHeight: responseHeight, maxHeight: responseHeight }}>
              <Table aria-label="collapsible table" stickyHeader>
                <UserListHead order={order} orderBy={orderBy} headLabel={TABLE_HEAD} rowCount={integratedAccountData.length}
                  numSelected={selectedAccounts.length} onRequestSort={handleRequestSort} onSelectAllClick={handleSelectAllClick}
                  isShowCheckbox={false}
                />
                {isNotFound ?
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper sx={{ textAlign: 'center' }}>
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  :
                  filteredAccounts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                    const { id, service_name, profile_name, profile_image, profile_type = '', is_active, is_enabled } = row;
                    const isSelected = selectedAccounts.findIndex(a => a.id === row.id) >= 0;
                    return (
                      <TableBody key={i}>
                        <TableRow hover key={i} tabIndex={-1} role="checkbox" selected={isSelected}>
                          <TableCell component="th" scope="row" sx={{ py: 3 }}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Badge overlap="circular" style={{ color: '#ffffff' }}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} badgeContent={getAvatar(service_name)} >
                                <Avatar
                                  src={profile_image ? profile_image : profile_name || id}
                                  alt={profile_name ? profile_name : id}
                                  sx={{
                                    alignItems: 'center', justifyContent: 'center', textAlign: 'center',
                                    height: 40, width: 40, bgcolor: '#00AB55',
                                  }}
                                  style={{ color: '#ffffff' }}
                                />
                              </Badge>
                              <Typography variant="subtitle2" noWrap>
                                {profile_name ? profile_name : id}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <Hidden only={['xs']}>
                            <TableCell align="left">{CommonHelper.DisplayServiceName(service_name)}</TableCell>
                            <TableCell align="left">{profile_type}</TableCell>
                            <TableCell align="left">
                              <Label color={is_active ? 'success' : 'error'}>{is_active ? 'Active' : 'Inactive'}</Label>
                            </TableCell>
                          </Hidden>
                          <TableCell align="left">
                            <StyledComponent.SwitchIcon sx={{ m: 1 }} checked={is_enabled} onChange={() => { handleAccountStatus(row) }}></StyledComponent.SwitchIcon>
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(e) => {
                                handleOpenMenu(e, row);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  })
                }
              </Table>
            </TableContainer>
            <TablePagination component="div" rowsPerPageOptions={[10, 15, 25]} count={integratedAccountData.length}
              rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Scrollbar>
        </DynamicHeightComponent>
        <IntegratedActionPopup {...postActionProps} />
        <ConfirmationDialog
          open={confirmOpen}
          onClose={() => setConfirmOpen(false)}
          onConfirm={handleDisAntegrateAccount}
          title="Confirm Deletion"
          message="Are you sure you want to delete this account?"
        />
        {facebookPageReAuthenticateOpen && <FacebookReAuthenticate handleClose={handleFacebookPage} AccountId={selectedPopOverAccount?.id} getConnectedNetworks={getConnectedNetworks} />}
        {instagramReAuthenticateOpen && <InstagramReAuthenticate handleClose={handleInstagram} AccountId={selectedPopOverAccount?.id} getConnectedNetworks={getConnectedNetworks} />}
        {youtubeReAuthenticateOpen && <YouTubeReAuthenticate handleClose={handleYoutube} AccountId={selectedPopOverAccount?.id} getConnectedNetworks={getConnectedNetworks} />}
        {linkedinPageReAuthenticateOpen && <LinkedInPageReAuthenticate handleClose={handleLinkedPage} AccountId={selectedPopOverAccount?.id} getConnectedNetworks={getConnectedNetworks} />}
        {linkedinProfileReAuthenticateOpen && <LinkedInProfileReAuthenticate handleClose={handleLinkedProfile} AccountId={selectedPopOverAccount?.id} getConnectedNetworks={getConnectedNetworks} />}
        {twitterReAuthenticateOpen && <TwitterReAuthenticate handleClose={handleCloseTwitter} AccountId={selectedPopOverAccount?.id} />}
        {twitterDeveloperReAuthenticateOpen && <TwitterDeveloperReAuthenticate handleClose={handleCloseTwitter} AccountId={selectedPopOverAccount?.id} />}
        {googleMyBusinessReAuthenticateeOpen && <GoogleMyBusinessReAuthenticate handleClose={handleGoogleMyBusiness} AccountId={selectedPopOverAccount?.id} getConnectedNetworks={getConnectedNetworks} />}
        {pinterestReAuthenticateOpen && <PinterestReAuthorizate handleClose={handlePinterest} AccountId={selectedPopOverAccount?.id} getConnectedNetworks={getConnectedNetworks} />}
        {tiktokReAuthenticateOpen && <TiktokReAuthenticate handleClose={handleTiktok} AccountId={selectedPopOverAccount?.id} getConnectedNetworks={getConnectedNetworks} />}
      </Card>
    </>
  );
};

export default IntegratdAccounts;
