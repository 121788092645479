import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import { Divider, Grid, useMediaQuery } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import FacebookPage_icon from '../../assets/facebook.svg';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import Instagram from '../../assets/instagram.svg';
import LinkedIn_Icon from '../../assets/linked_in.svg';
import Threads_Icon from '../../assets/threads.png';
import Pinterest_Icon from '../../assets/pinterest-icon.svg';
import Twitter from '../../assets/twitter.svg';
import YouTube_Icon from '../../assets/youtube.png';
import GBusiness from '../../assets/google-my-business-logo.svg';
import TikTok_Icon from '../../assets/tiktok.svg';
import InstagramIntegration from './instagramIntegration/instagramIntegration.component';
import TwitterIntegration from './twitterIntegration/twitterIntegration.component';
import YouTubeIntegration from './youtubeIntegration/youtubeIntegration.component';
import PinterestIntegration from './pinterestIntegration/pinteresIntegration.component';
import FacebookPageIntegration from './facebookIntegration/facebookIntegration.component';
import FacebookGroupIntegration from './facebookIntegration/facebookGroupIntegration.component';
import LinkedInProfileIntegration from './linkedinIntegration/linkedinProfileIntegration.component';
import LinkedInPageIntegration from './linkedinIntegration/linkedinPageIntegration.component';
import { integrateModuleURL } from 'src/services/urlService';
import { useToaster } from 'src/utils/toaster/toasterContext';
import HttpServices from 'src/services/httpService';
import { useTheme } from '@mui/material/styles';
import TiktokIntegration from './tiktokIntegration/tiktokIntegration.component';
import GoogleMyBusinessIntegration from './google-my-business-integration/google-my-business-integration.component';
import ThreadsIntegration from './threads-integration/threads-integration.component';
import { CommonHelper } from 'src/utils/commonHelper';
import TwitterAppSumoIntegration from './twitterAppsumoUser-integration/twitterAppSumoIntegration.component';

const NewIntegrationComponent = () => {
  const isAppSumoUser = CommonHelper.GetBrandInfoFromLocalStorage('is_appsumo_user');

  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:599px)');
  const { showToast } = useToaster();

  //State Variables
  const [facebookPageIntegrationOpen, setfacebookPageIntegrationOpen] = useState(false);
  const [facebookGroupIntegrationOpen, setfacebookGroupIntegrationOpen] = useState(false);
  const [instagramIntegrationOpen, setInstagramIntegrationOpen] = useState(false);
  const [twitterIntegrationOpen, setTwitterIntegrationOpen] = useState(false);
  const [twitterDeveloperIntegrationOpen, setTwitterDeveloperIntegrationOpen] = useState(false);
  const [youtubeIntegrationOpen, setYoutubeIntegrationOpen] = useState(false);
  const [googleMyBusinessIntegrationOpen, setGoogleMyBusinessIntegrationOpen] = useState(false);
  const [threadsIntegrationOpen, setThreadsIntegrationOpen] = useState(false);
  const [linkedInProfileIntegrationOpen, setLinkedInProfileIntegrationOpen] = useState(false);
  const [linkedInPageIntegrationOpen, setLinkedInPageIntegrationOpen] = useState(false);
  const [tiktokIntegrationOpen, setTiktokIntegrationOpen] = useState(false);
  const [pinterestIntegrationOpen, setPinterestIntegrationOpen] = useState(false);
  const responseHeight =  isMobile ? 'calc(100vh - 200px)' : 'calc(100vh - 170px)'

  const handleTiktok = (show = false) => {
    setTiktokIntegrationOpen(show);
  };

  const handlePinterest = (show = false) => {
    setPinterestIntegrationOpen(show);
  };
  const handleYoutube = (show = false) => {
    setYoutubeIntegrationOpen(show);
  };
  const handleGoogleMyBusiness = (show = false) => {
    setGoogleMyBusinessIntegrationOpen(show);
  };
  const handleThreads = (show = false) => {
    setThreadsIntegrationOpen(show);
  };
  const handleFacebookPage = (show = false) => {
    setfacebookPageIntegrationOpen(show);
  };
  const handleFacebookGroup = (show = false) => {
    setfacebookGroupIntegrationOpen(show);
  };
  const handleInstagram = (show = false) => {
    setInstagramIntegrationOpen(show);
  };
  const handleTwitter = (show = false) => {
    setTwitterIntegrationOpen(show);
  };
  const handleDeveloperTwitter = (show = false) => {
    setTwitterDeveloperIntegrationOpen(show);
  };
  const handleLinkedInProfile = (show = false) => {
    setLinkedInProfileIntegrationOpen(show);
  };
  const handleLinkedInPage = (show = false) => {
    setLinkedInPageIntegrationOpen(show);
  };
  const contactUsSuccessCallback = (data, message) => {
    showToast('Please wait until, we will get back to you', 's');
  };
  const failureCallback = (message) => {
    message && showToast(message, 'e');
  };
  const handleContactUs = () => {
    let getProps = {
      url: integrateModuleURL.handleContactUs,
      successCallback: contactUsSuccessCallback,
      failureCallback,
    };
    HttpServices.Get(getProps);
  };

  //Styled Components
  const SmallAvatarChild_f = styled(Avatar)(({ theme }) => ({
    fontSize: 'large',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto',
    backgroundColor: '#ffffff',
  }));

  const SmallAvatar_t = styled(Avatar)(({ theme }) => ({
    fontSize: 'large',
    backgroundColor: '#17A6FC',
  }));

  const SmallAvatar_i = styled(Avatar)(({ theme }) => ({
    fontSize: 'large',
  }));

  const SmallAvatar_ln = styled(Avatar)(({ theme }) => ({
    fontSize: 'large',
    backgroundColor: '#0288D1',
  }));

  const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));

  return (
    <>
      <Helmet>
        <title> Integration </title>
      </Helmet>
      <>
        <Grid
          xs={12}
          md={6}
          sx={{
            borderRadius: 2,
            mt: isMobile ? 2  : 3,
            maxHeight: responseHeight,
            minHeight: responseHeight,
            overflowY: 'auto',
          }}
        >
          <Demo>
            <Card>
              <List>
                <ListItem
                  secondaryAction={
                    <LoadingButton
                      variant="contained"
                      loading={facebookPageIntegrationOpen}
                      endIcon={<SendIcon />}
                      size="small"
                      onClick={() => {
                        handleFacebookPage(true);
                      }}
                    >
                      <Hidden only={['xs', 'sm']}>Connect</Hidden>
                    </LoadingButton>
                  }
                >
                  <ListItemAvatar>
                    <img sx={{ variant: 'rounded' }} width={30} height={30} src={FacebookPage_icon} />
                  </ListItemAvatar>
                  <ListItemText primary="Facebook Page" />
                </ListItem>
              </List>
              <Divider />
              <Divider />
              <List>
                <ListItem
                  secondaryAction={
                    <LoadingButton
                      variant="contained"
                      loading={instagramIntegrationOpen}
                      endIcon={<SendIcon />}
                      size="small"
                      onClick={() => {
                        handleInstagram(true);
                      }}
                    >
                      <Hidden only={['xs', 'sm']}>Connect</Hidden>
                    </LoadingButton>
                  }
                >
                  <ListItemAvatar>
                    <img sx={{ variant: 'rounded' }} width={25} height={30} src={Instagram} />
                  </ListItemAvatar>
                  <ListItemText primary="Instagram" />
                </ListItem>
              </List>
              <Divider />
              {isAppSumoUser ? (
                <List>
                  <ListItem
                    secondaryAction={
                      <LoadingButton
                        variant="contained"
                        loading={twitterDeveloperIntegrationOpen}
                        size="small"
                        endIcon={<SendIcon />}
                        onClick={() => {
                          handleDeveloperTwitter(true);
                        }}
                      >
                        <Hidden only={['xs', 'sm']}>Connect</Hidden>
                      </LoadingButton>
                    }
                  >
                    <ListItemAvatar>
                      <img sx={{ variant: 'rounded' }} width={25} height={25} src={Twitter} />
                    </ListItemAvatar>
                    <ListItemText primary="Twitter" />
                  </ListItem>
                </List>
              ) : (
                <List>
                  <ListItem
                    secondaryAction={
                      <LoadingButton
                        variant="contained"
                        loading={twitterIntegrationOpen}
                        size="small"
                        endIcon={<SendIcon />}
                        onClick={() => {
                          handleTwitter(true);
                        }}
                      >
                        <Hidden only={['xs', 'sm']}>Connect</Hidden>
                      </LoadingButton>
                    }
                  >
                    <ListItemAvatar>
                      <img sx={{ variant: 'rounded' }} width={25} height={25} src={Twitter} />
                    </ListItemAvatar>
                    <ListItemText primary="Twitter" />
                  </ListItem>
                </List>
              )}
              <Divider />
              <List>
                <ListItem
                  secondaryAction={
                    <LoadingButton
                      variant="contained"
                      loading={youtubeIntegrationOpen}
                      endIcon={<SendIcon />}
                      size="small"
                      onClick={() => {
                        handleYoutube(true);
                      }}
                    >
                      <Hidden only={['xs', 'sm']}>Connect</Hidden>
                    </LoadingButton>
                  }
                >
                  <ListItemAvatar>
                    <img sx={{ variant: 'rounded' }} width={35} height={35} src={YouTube_Icon} />
                  </ListItemAvatar>
                  <ListItemText primary="Youtube" />
                </ListItem>
              </List>
              <Divider />
              <List>
                <ListItem
                  secondaryAction={
                    <LoadingButton
                      variant="contained"
                      loading={linkedInProfileIntegrationOpen}
                      endIcon={<SendIcon />}
                      size="small"
                      onClick={() => {
                        handleLinkedInProfile(true);
                      }}
                    >
                      <Hidden only={['xs', 'sm']}>Connect</Hidden>
                    </LoadingButton>
                  }
                >
                  <ListItemAvatar>
                    <img sx={{ variant: 'rounded' }} width={30} height={30} src={LinkedIn_Icon} />
                  </ListItemAvatar>
                  <ListItemText primary="LinkedIn Profile" />
                </ListItem>
              </List>
              <Divider />
              <List>
                <ListItem
                  secondaryAction={
                    <LoadingButton
                      variant="contained"
                      loading={linkedInPageIntegrationOpen}
                      endIcon={<SendIcon />}
                      size="small"
                      onClick={() => {
                        handleLinkedInPage(true);
                      }}
                    >
                      <Hidden only={['xs', 'sm']}>Connect</Hidden>
                    </LoadingButton>
                  }
                >
                  <ListItemAvatar>
                    <img sx={{ variant: 'rounded' }} width={30} height={30} src={LinkedIn_Icon} />
                  </ListItemAvatar>
                  <ListItemText primary="LinkedIn Page" />
                </ListItem>
              </List>
              <Divider />
              <List>
                <ListItem
                  secondaryAction={
                    <LoadingButton
                      variant="contained"
                      loading={pinterestIntegrationOpen}
                      endIcon={<SendIcon />}
                      size="small"
                      onClick={() => {
                        handlePinterest(true);
                      }}
                    >
                      <Hidden only={['xs', 'sm']}>Connect</Hidden>
                    </LoadingButton>
                  }
                >
                  <ListItemAvatar>
                    <img sx={{ variant: 'rounded' }} width={30} height={30} src={Pinterest_Icon} />
                  </ListItemAvatar>
                  <ListItemText primary="Pinterest" />
                </ListItem>
              </List>
              <Divider />
              <List>
                <ListItem
                  secondaryAction={
                    <LoadingButton
                      variant="contained"
                      loading={googleMyBusinessIntegrationOpen}
                      endIcon={<SendIcon />}
                      size="small"
                      onClick={() => {
                        handleGoogleMyBusiness(true);
                      }}
                    >
                      <Hidden only={['xs', 'sm']}>Connect</Hidden>
                    </LoadingButton>
                  }
                >
                  <ListItemAvatar>
                    <img sx={{ variant: 'rounded' }} width={30} height={30} src={GBusiness} />
                  </ListItemAvatar>
                  <ListItemText primary="Google My Business" />
                </ListItem>
              </List>
              <Divider />
              <List>
                <ListItem
                  secondaryAction={
                    <LoadingButton
                      variant="contained"
                      loading={tiktokIntegrationOpen}
                      endIcon={<SendIcon />}
                      size="small"
                      onClick={() => {
                        handleTiktok(true);
                      }}
                    >
                      <Hidden only={['xs', 'sm']}>Connect</Hidden>
                    </LoadingButton>
                  }
                >
                  <ListItemAvatar>
                    <img sx={{ variant: 'rounded' }} width={26} height={26} src={TikTok_Icon} />
                  </ListItemAvatar>
                  <ListItemText primary="TikTok" />
                </ListItem>
              </List>
              <Divider />
              <List>
                <ListItem
                  secondaryAction={
                    <LoadingButton
                      variant="contained"
                      loading={threadsIntegrationOpen}
                      endIcon={<HourglassEmptyIcon />}
                      size="small"
                      // onClick={() => {
                      //   handleThreads(true);
                      // }}
                    >
                      <Hidden only={['xs', 'sm']}>Coming Soon</Hidden>
                    </LoadingButton>
                  }
                >
                  <ListItemAvatar>
                    <img sx={{ variant: 'rounded' }} width={30} height={30} src={Threads_Icon} />
                  </ListItemAvatar>
                  <ListItemText primary="Threads" />
                </ListItem>
              </List>
            </Card>
          </Demo>
        </Grid>
        {facebookPageIntegrationOpen && <FacebookPageIntegration handleClose={handleFacebookPage} />}
        {facebookGroupIntegrationOpen && <FacebookGroupIntegration handleClose={handleFacebookGroup} />}
        {instagramIntegrationOpen && <InstagramIntegration handleClose={handleInstagram} />}
        {twitterIntegrationOpen && <TwitterIntegration handleClose={handleTwitter} />}
        {youtubeIntegrationOpen && <YouTubeIntegration handleClose={handleYoutube} />}
        {linkedInProfileIntegrationOpen && <LinkedInProfileIntegration handleClose={handleLinkedInProfile} />}
        {linkedInPageIntegrationOpen && <LinkedInPageIntegration handleClose={handleLinkedInPage} />}
        {tiktokIntegrationOpen && <TiktokIntegration handleClose={handleTiktok} />}
        {googleMyBusinessIntegrationOpen && <GoogleMyBusinessIntegration handleClose={handleGoogleMyBusiness} />}
        {threadsIntegrationOpen && <ThreadsIntegration handleClose={handleThreads} />}
        {twitterDeveloperIntegrationOpen && <TwitterAppSumoIntegration handleClose={handleDeveloperTwitter} />}
        {pinterestIntegrationOpen && <PinterestIntegration handleClose={handlePinterest}/>}
      </>
    </>
  );
};

export default NewIntegrationComponent;
