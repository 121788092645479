import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import {
    Badge,
    Avatar,
    Card,
    IconButton,
    Table, TableBody, TableCell, TableContainer, TablePagination, TableRow
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from "react-helmet-async";
import Label from 'src/components/Label';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import HttpServices from 'src/services/httpService';
import { userModuleURL } from 'src/services/urlService';
import DynamicHeightComponent from 'src/utils/dynamicSize.component';
import Spinner from 'src/utils/Loader/spinner';
import { useToaster } from 'src/utils/toaster/toasterContext';
import UserListHead from '../../sections/@dashboard/user/UserListHead';
import NoPostFound from '../publishedPosts/noPost.component';
import DeleteIcon from '../../assets/deletecomment.svg'
import RolesToolbar from './rolesToolbar';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import { CommonHelper } from 'src/utils/commonHelper';
import { PostDetailsHelper } from 'src/utils/postDetailsHelper';
import UpdateRole from './updateRole.component';

const ManageRoles = () => {
    const [roles, setRoles] = useState([]);
    const [updateRole,setUpadateRole] = useState();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loader, setLoader] = useState(false);
    const { showToast } = useToaster()
    const [toBeDeletedId, setToBeDeletedId] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [openUpdateRoleModel, setUpdateRoleModel] = useState(false);
    const [height, setHeight] = useState(window.innerHeight - 250);

    useEffect(() => {
        getAllRoles();
    }, []);
    const getAllRoles = () => {
        setLoader(true)
        let postProps = {
            url: userModuleURL.getAllRoles,
            successCallback,
            failureCallback
        };
        HttpServices.Get(postProps);
    }
    const successCallback = (data, message) => {
        message && showToast(message, "s");
        setLoader(false);
        setRoles(data);
    }
    const failureCallback = (message) => {
        showToast(message, "e")
        setLoader(false)
    }
    const deleteSuccessCallback = (data, message) => {
        message && showToast(message, "s")
        setLoader(false);
        getAllRoles();
    }
    const handleDelete = (id) => {
        setToBeDeletedId(id);
        setConfirmOpen(true);
    }
    const handleUpdate = (data) => {
        setUpadateRole(data);
        setUpdateRoleModel(true)
    }
    const handleClose = () => {
        setUpdateRoleModel(false);
    };

    const confirmDelete = () => {
        setConfirmOpen(false);
        let roleprops = {
            role_id: toBeDeletedId
        }
        let postProps = {
            url: userModuleURL.deleteRole,
            body: roleprops,
            successCallback: deleteSuccessCallback,
            failureCallback
        }
        HttpServices.Post(postProps)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const getRolePermissionView = (rolePermission) => {
        return rolePermission?.role_permissions?.filter(a => a.has_permission).map(permission => {
            return <Label color='primary' style={{ margin: 0.5 }}>{permission.permission_display_name}</Label>
        })
    }
    const getConnectedSocialMediaView = (rolePermission) => {
        return rolePermission?.connected_profiles?.map(profile => {
            return <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={CommonHelper.GetSocialMediaAvatar(profile.service_name)}
                sx={{ mr: 3, zIndex: 0 }}
            >
                <Avatar
                    src={profile.profile_image ? profile.profile_image : profile.profile_name || profile.id}
                    alt={profile.profile_name ? profile.profile_name : profile.id}
                    sx={{ height: 40, width: 40, bgcolor: 'primary.main' }}
                />
            </Badge>
        })
    }
    const handleRoleCreated = (message) => {
        message && showToast(message, "s");
        setUpdateRoleModel(false);
        getAllRoles();
    }
    return (
        <>
            <Helmet>
                <title> Manage Roles </title>
            </Helmet>
            {loader ?
                <Spinner />
                :
                <>
                    {roles && roles.length > 0 ?
                        <Card sx={{ m: 1 ,minHeight: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)'}}>
                            <RolesToolbar handleSuccess={handleRoleCreated} />
                            <DynamicHeightComponent >
                                <Scrollbar>
                                    <TableContainer sx={{
                                        maxHeight: height, height: height - 60
                                    }} >
                                        <Table aria-label="collapsible table" stickyHeader>
                                            <UserListHead
                                                headLabel={[
                                                    { id: 'role', label: 'Role',width: '15%', alignCenter: false },
                                                    { id: 'Permissions', label: 'Permissions',width: '40%', alignRight: false },
                                                    { id: 'connectedProfiles', label: "Connected Social Media's",width: '35%', alignRight: false },
                                                    { id: 'action', label: 'Action',width: '10%', alignCenter: true },
                                                ]}
                                                rowCount={roles.length}
                                                isShowCheckbox={false}
                                                style={{ whiteSpace: 'nowrap' }}
                                            />
                                            <TableBody>
                                                {roles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((role) => (
                                                    <TableRow hover key={role.id} tabIndex={-1}>
                                                        <TableCell align="left">{role.role_name}</TableCell>
                                                        <TableCell align="left">
                                                            {getRolePermissionView(role)}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {PostDetailsHelper.getSocialMediaViewWithTooltip(role.connected_profiles)}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {!role.is_default && <IconButton onClick={() => { handleDelete(role.id) }} size="large">
                                                            <img src={DeleteIcon} style={{ width: 23, height: 23 }} />
                                                            </IconButton>}
                                                            {!role.is_default && <IconButton onClick={() => { handleUpdate(role) }} size="large">
                                                            <EditIcon sx={{ color: 'primary.main' }}  />
                                                            </IconButton>}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        component="div"
                                        rowsPerPageOptions={[ 10,15, 25]}
                                        count={roles.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Scrollbar>
                            </DynamicHeightComponent>
                            <ConfirmationDialog
                                open={confirmOpen}
                                onClose={() => setConfirmOpen(false)}
                                onConfirm={confirmDelete}
                                title="Confirm Deletion"
                                message="Are you sure you want to delete this Role?"
                            />
                            {openUpdateRoleModel && <UpdateRole handleClose={handleClose} updateRole={updateRole} handleSuccess={handleRoleCreated}/>}
                        </Card>
                        :
                        <Card sx={{minHeight: 'calc(100vh - 150px)', maxHeight: 'calc(100vh - 150px)'}}>
                            <RolesToolbar handleSuccess={handleRoleCreated} />
                            <NoPostFound height={height} />
                        </Card>
                    }
                </>
            }
        </>
    );
};

export default ManageRoles;
